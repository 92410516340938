<template>
  <!-- As a link -->
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <router-link class="navbar-brand" :to="{ name: 'home'}">
        <img src="@/assets/ebug-logo-only.svg" alt="" height="24" class="d-inline-block align-text-bottom">
        eBug Meter Readings
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar" aria-controls="mynavbar" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="mynavbar">
        <div class="navbar-nav me-auto mb-2 mb-lg-0">
          <router-link class="nav-link" active-class="active" :to="{ name: 'home'}">Home</router-link>
          <router-link class="nav-link" active-class="active" :to="{ name: 'about'}">About</router-link>
        </div>
        <div class="navbar-nav ms-auto">
          <template v-if="!this.isAuthenticated">
            <button class="btn btn-primary me-2" type="submit" @click="login">Log in</button>
          </template>
          <template v-if="this.isAuthenticated">
            <router-link class="nav-link me-2" active-class="active" :to="{name: 'profile'}">{{ user.email }}</router-link>
            <button class="btn btn-secondary" type="submit" @click="logout">Log out</button>
          </template>
        </div>
      </div>
    </div>
  </nav>

  <router-view/>
</template>

<script>
export default {
  data: function () {
    return {
      user: this.$auth0.user,
      isAuthenticated: this.$auth0.isAuthenticated
    };
  },
  methods: {
    login() {
      this.$auth0.loginWithRedirect();
    },
    logout() {
      this.$auth0.logout({ returnTo: window.location.origin });
    }
  }
};
</script>

<style>

</style>
