<template>
  <div>
    <h1>Communities</h1>
    <div class="list-group">
      <template v-for="community in this.topLevelCommunities" :key="community.CommunityGuid">
          <router-link :to="{ name: 'community_actions', params: { parentguid: community.CommunityGuid }}" class="list-group-item list-group-item-action">
            {{ community.Name }}
          </router-link>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommunityList",
  computed: {
    communityList() {
      if (this.$store.state.communityList.length === 0) {
        // It's empty, fetch from API
        this.fetchCommunityList();
      }
      return this.$store.state.communityList;
    },
    topLevelCommunities() {
      var filteredList = [];
      var allCommunities = this.communityList
      for(const communityIndex in allCommunities) {
        if(allCommunities[communityIndex].ParentCommunityGuid !== undefined) {
          if (allCommunities[communityIndex].ParentCommunityGuid.Valid === false) {
            filteredList.push(allCommunities[communityIndex]);
          } else if (this.parentInCommunities(allCommunities[communityIndex].ParentCommunityGuid.String)) {
            // Has a parent that we have in the list
          } else {
            filteredList.push(allCommunities[communityIndex]);
          }
        } else {
          console.log("Parent undefined", allCommunities[communityIndex]);
          filteredList.push(allCommunities[communityIndex]);
        }
      }
      return filteredList;
    },
  },
  methods: {
    async fetchCommunityList() {
      console.log("fetching communities");
      const token = await this.$auth0.getAccessTokenSilently();
      fetch(process.env.VUE_APP_API_BASE+'/communities', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
          .then(response => response.json())
          .then(communities => {
            this.$store.commit("setCommunityList", communities);
            console.log("Fetched community list from api", communities);
          });
    },
    parentInCommunities(parentGuid) {
      var allCommunities = this.communityList;
      for(const communityIndex in allCommunities) {
        var community = allCommunities[communityIndex];
        if(community.CommunityGuid === parentGuid) {
          return true;
        }
      }
      return false;
    }
  },
}
</script>

<style scoped>

</style>