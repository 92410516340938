import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {authGuard} from "@auth0/auth0-vue";
// import {authGuard} from "@auth0/auth0-vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/LogoutView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/UserProfile.vue'),
    // beforeEnter: authGuard
  },
  {
    path: '/community/:parentguid',
    name: 'community_actions',
    component: () => import('../views/CommunityActions.vue'),
    props: true,
    beforeEnter: authGuard
  },
  {
    path: '/community/:parentguid/readings/:datetime',
    name: 'community_readings',
    component: () => import('../views/LastReadingsAt.vue'),
    props: true,
    beforeEnter: authGuard
  },
  {
    path: '/community/:parentguid/graph',
    name: 'facility_graph',
    component: () => import('../views/FacilityGraph.vue'),
    props: true,
    beforeEnter: authGuard
  },
  {
    path: '/community/:parentguid/wsq-water-breakdown/:year/:month',
    name: 'wsq-water-breakdown',
    component: () => import('../views/WsqWaterBreakdown.vue'),
    props: true,
    beforeEnter: authGuard
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from) => {
//   if (to.path === '/login') {
//     return true;
//   }
//   return authGuard(to, from);
// })

export default router
