import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {createAuth0} from '@auth0/auth0-vue';

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

const app = createApp(App);
app.use(store);
app.use(router);

app.use(
    createAuth0({
        domain: 'auth.ebug.co.za',
        client_id: 'fMTzvuAALp6Ai2X8qC5b5YbS7W8KbOcb',
        redirect_uri: window.location.origin,
        audience: "https://api.ebug.co.za"
    })
);

import formatDateTime from './plugins/formatDateTime'
app.use(formatDateTime)

app.mount('#app');
