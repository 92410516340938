<template>
  <div class="container">
    <template v-if="!isAuthenticated">
      <p>Log in to use this website.</p>
      <button class="btn btn-primary" @click="login">Log in</button>
    </template>

    <template v-if="isAuthenticated">
      <CommunitiesList/>
    </template>
  </div>
</template>

<script>

import CommunitiesList from "@/views/CommunitiesList";
export default {
  name: 'HomeView',
  components: {
    CommunitiesList
  },
  data: function () {
    return {
      user: this.$auth0.user,
      isAuthenticated: this.$auth0.isAuthenticated
    };
  },
  methods: {
    login() {
      this.$auth0.loginWithRedirect();
    }
  }
}
</script>
