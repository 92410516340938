import moment from "moment-timezone";

export default {
    install: (app) => {
        app.config.globalProperties.$formatDateTimeInZone = (timezoneName, datetime) => {
            if(datetime === undefined) {
                return "";
            }
            if(timezoneName === undefined || timezoneName === "") {
                timezoneName = "Africa/Johannesburg";
            }
            let momentObject = moment(datetime);
            momentObject = momentObject.tz(timezoneName);
            return momentObject.format('YYYY-MM-DD HH:mm');
        }
    }
}